<template>
  <div class="aiz-user-panel">
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('gou-mai-li-shi') }}</h5>
      </div>
      <div class="card-body">
        <table class="table mb-0 " :class="dataList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
          <tr>
            <th>{{ $t('bian-hao') }}</th>
            <th data-breakpoints="md">{{ $t('ri-qi') }}</th>
            <th data-breakpoints="md">
              {{ $t('ding-dan-bian-hao') }} </th>
            <th>{{ $t('jin-e') }}</th>
            <th data-breakpoints="md">{{ $t('jiao-huo-zhuang-tai') }}</th>
            <th class="text-right">{{ $t('cao-zuo') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in dataList" :key="i">
            <td>
              <a>{{ item.ID }}</a>
            </td>
            <td>{{ item.CreatedAt }}</td>
            <td>
              <a @click="toDetail(item)">{{item.OrderNo}}</a>
            </td>
            <td>
              {{ item.TotalAmount }}
            </td>
            <td>
              <span  class="badge badge-inline badge-info" v-if="item.Status == 0">{{ $t('Processing') }}</span>
              <span  class="badge badge-inline badge-info" v-if="item.Status == 1">{{ $t('Processing') }}</span>
              <span  class="badge badge-inline badge-info" v-if="item.Status == 2">{{ $t('zai-tu-zhong') }}</span>
              <span  class="badge badge-inline badge-success" v-if="item.Status == 3">{{ $t('yi-qian-shou') }}</span>
              <span  class="badge badge-inline badge-danger" v-if="item.Status == 4">{{ $t('Illegal order') }}</span>
              <span  class="badge badge-inline badge-danger" v-if="item.Status == 5">{{ $t('yi-qu-xiao') }}</span>
            </td>
            <td class="text-right">
              <a href="javascript:void(0)" class="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete" :title="$t('qu-xiao')">
                <i class="las la-trash"></i>
              </a>
              <a class="btn btn-soft-info btn-icon btn-circle btn-sm" :title="$t('xiang-qing')" @click="toDetail(item)">
                <i class="las la-eye"></i>
              </a>
              <a class="btn btn-soft-warning btn-icon btn-circle btn-sm"  :title="$t('xia-zai-fa-piao')" v-if="false">
                <i class="las la-download"></i>
              </a>
            </td>
          </tr>
          <tr class="footable-empty" v-if="dataList.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>
    <Popup v-model="showPopup">
      <div class="modal fade" id="order_details" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div id="order-details-modal-body">

            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { orderList } from '@/api/user'
import pageBar from '@/components/page'
import {Form, Popup} from 'vant'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      dataList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      orderList(form).then(res => {
        this.dataList = res.data.Items
        this.page.total = res.data.Pagination.totalPage
      })
    },
    toDetail(data) {
      sessionStorage.setItem('orderData', JSON.stringify(data))
      this.$router.push({
        name: 'buyOrderDetail',
        query: {
          id: data.ID
        }
      })
    },
    changePage(page) {
      this.page.page = page
      this.init()
    }
  }
}
</script>